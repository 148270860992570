import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/index.css';
import './assets/scss/theme.scss';

import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import env from './env';
import ReactDOM from 'react-dom/client';
import store from './libs/redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: env.sentryDns,
  integrations: [new Sentry.BrowserTracing({})],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
