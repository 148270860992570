declare var gtag: any;

const useGtag = () => {
  const sendEvent = (event: string, params: object = {}) => {
    gtag('event', event, params);
  };

  return {
    sendEvent,
  };
};

export default useGtag;
