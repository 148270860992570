import FlatPickr from 'react-flatpickr';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.min.css';
import './DatePicker.css';

const DatePicker = ({ invalid = false, feedback = '', setValue, ...props }) => {
  const handleChange = (dates) => {
    let formattedDate = null;

    if (dates.length > 0) {
      const selectedDate = dates[0];
      const dateObj = moment(selectedDate);

      formattedDate = dateObj.isValid() ? dateObj.format('MM/DD/YYYY') : null;
    }

    setValue(formattedDate);
  };

  return (
    <div className='form-group'>
      <FlatPickr
        key={invalid}
        placeholder='Select a date'
        className={invalid ? 'is-invalid' : ''}
        options={{
          altInput: true,
          altFormat: 'm/d/Y',
          dateFormat: 'm/d/Y',
          maxDate: moment().format('MM/DD/YYYY'),
          allowInput: true,
          disableMobile: true,
        }}
        onChange={handleChange}
        onClose={handleChange}
        {...props}
      />
      {invalid && <div className='invalid-feedback'>{feedback}</div>}
    </div>
  );
};

export default DatePicker;
