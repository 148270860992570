import { WorkplaceIndividual } from '../models/WorkplaceIndividual';

export const WORKPLACE_INDIVIDUAL = 'workplace-individual';
export const WORKPLACE_TEAM = 'workplace-team';

export const MODE_SINGLE = 'single';
export const MODE_TEAM = 'team';

const LS_DEFAULT_ROUTE = 'preliminary-result';

const quizModels: any = {
  [WORKPLACE_INDIVIDUAL]: WorkplaceIndividual,
};

const quizLastStepRoutes: any = {
  [WORKPLACE_TEAM]: 'completed',
};

export const getQuizModel = (key: string) => quizModels[key];

export const getQuizLastStepRoute = (key: string): string => {
  const path = quizLastStepRoutes[key] || LS_DEFAULT_ROUTE;

  return `/${key}/${path}`;
};
