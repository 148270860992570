import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressProps } from '../../../components/layout/QuizLayout';

const useStartQuiz = ({ quiz, additionalSteps = [] }: any) => {
  const totalSteps = quiz.total_surveys + additionalSteps.length;
  const situationSteps = quiz.situation.length + additionalSteps.length;

  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [navbar, setNavbar] = useState({});
  const [progress, setProgress] = useState({
    step: 1,
    title: '',
    totalSteps,
    percentage: 0,
  });

  const navigate = useNavigate();

  const situationSurvey = quiz.situation[step - 1];
  const header = { title: quiz.name };

  useEffect(() => {
    updateNavbar();
    updateProgress();
    setIsLoading(false);
  }, [step]);

  useEffect(() => {
    scrollToWindowTop();
  }, [isLoading]);

  const updateNavbar = () => {
    setNavbar({
      leftAction: canGoBack()
        ? {
            content: 'Back',
            onClick: stepBack,
          }
        : { content: 'Cancel', onClick: () => navigate('/') },
    });
  };

  const updateProgress = () => {
    const survey = situationSurvey?.survey;
    const title =
      survey?.name || additionalSteps[step - quiz.situation.length - 1]?.name;

    setProgress((prev: ProgressProps) => ({
      ...prev,
      step,
      title,
      percentage: calculatePercentage(),
    }));
  };

  const stepBack = () => {
    setIsLoading(true);
    setStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const stepForward = () => {
    setIsLoading(true);
    setStep((prev) => (prev < situationSteps ? prev + 1 : prev));
  };

  const calculatePercentage = () => {
    return Math.round(((step - 1) * 100) / totalSteps);
  };

  const scrollToWindowTop = () => {
    const options: any = {
      top: 80,
      left: 0,
      behavior: 'instant',
    };

    window.scrollTo(options);
  };

  const canGoBack = () => step > 1;

  const canGoForward = () => step < situationSteps;

  return {
    isLoading,
    header,
    navbar,
    progress,
    setProgress,
    situationSurvey,
    canGoForward,
    stepForward,
  };
};

export default useStartQuiz;
