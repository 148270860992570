import * as types from './types';
import * as quizApi from '../../api/quizApi';

export function loadSurveyQuestionsSuccess(payload) {
  return { type: types.LOAD_SURVEY_QUESTIONS_SUCCESS, payload };
}

export function invalidateSurveyQuestionsState() {
  return { type: types.INVALIDATE_SURVEY_QUESTIONS_STATE };
}

export function getSurveyQuestions(surveyStatId, params = {}) {
  return function (dispatch) {
    return quizApi.getQuizSurveyQuestions(surveyStatId, params).then((res) => {
      dispatch(loadSurveyQuestionsSuccess(res));
      return res;
    });
  };
}
