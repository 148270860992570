export const getQuizAccessToken = (slug) => {
  const key = getAccessTokenKey(slug);
  const token = localStorage.getItem(key);

  return token?.trim() || null;
};

export const setQuizAccessToken = (slug, token) => {
  const key = getAccessTokenKey(slug);
  localStorage.setItem(key, token);
};

export const removeQuizAccessToken = (slug) => {
  const key = getAccessTokenKey(slug);
  localStorage.removeItem(key);
};

export const getQuizSessionToken = () => {
  const key = getSessionTokenKey();
  return sessionStorage.getItem(key);
};

export const setQuizSessionToken = (token) => {
  const key = getSessionTokenKey();
  sessionStorage.setItem(key, token);
};

export const removeQuizSessionToken = () => {
  const key = getSessionTokenKey();
  sessionStorage.removeItem(key);
};

export const setQuizCompletedCookie = (slug) => {
  const cookieName = getQuizCompletedKey(slug);
  document.cookie = `${cookieName}=limit_reached; path=/; max-age=1800`;
};

export const quizCompletedCookieExists = (slug) => {
  const cookieName = getQuizCompletedKey(slug);
  const cookiesArray = document.cookie.split(';');

  return cookiesArray.some((c) => c.trim().startsWith(cookieName + '='));
};

const getAccessTokenKey = (slug) => `${slug}:token`;

const getSessionTokenKey = () => `quiz:token`;

const getQuizCompletedKey = (slug) => `${slug}:completed`;
