import React, { useState } from 'react';
import SubmitButton from '../../../SubmitButton';
import './Introduction.css';

export interface Props {
  skippable: boolean;
  skip: () => Promise<any>;
  start: () => Promise<any>;
  title?: string;
  coverImage?: string;
  [key: string]: any;
}

const Introduction: React.FC<Props> = ({
  skippable,
  skip,
  start,
  title,
  coverImage,
  children,
}) => {
  const [isStarting, setIsStarting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);

  const isDisabled = isStarting || isSkipping;

  const onStartHandler = () => {
    setIsStarting(true);
    start().finally(() => setIsStarting(false));
  };

  const onSkipHandler = () => {
    setIsSkipping(true);
    skip().finally(() => setIsSkipping(false));
  };

  return (
    <div className='introduction'>
      {coverImage && (
        <div className='cover'>
          <img alt='Cover' src={coverImage} />
        </div>
      )}
      {title && <div className='title'>{title}</div>}
      <div className='content'>{children}</div>
      <SubmitButton
        className='action'
        isSubmitting={isStarting}
        disabled={isDisabled}
        onClick={onStartHandler}
      >
        Start
      </SubmitButton>
      {skippable && (
        <SubmitButton
          className='action'
          bsColor='light'
          isSubmitting={isSkipping}
          disabled={isDisabled}
          onClick={onSkipHandler}
        >
          Skip this session
        </SubmitButton>
      )}
    </div>
  );
};

export default Introduction;
