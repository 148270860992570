import { toast } from 'react-toastify';
import { sendReport } from '../../../libs/api/quizStatApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSuccessCallback: (params: any) => void;
}

const useSendReportForm = ({ onSuccessCallback }: Props) => {
  const validationSchema: any = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
      .required('Email is required.')
      .email('Email format is invalid.'),

    terms: Yup.boolean().isTrue('Consent required to proceed.'),
    email_consent: Yup.boolean().isTrue('You have to agree to receive emails.'),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    sendReport(values)
      .then(() => onSuccessCallback(values))
      .catch(({ message }: any) => {
        toast.error(message);
        setSubmitting(false);
      });
  };

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      terms: false,
      email_consent: false,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return {
    form,
  };
};

export default useSendReportForm;
