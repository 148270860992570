import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uniqueEmailValidator } from './utils';
import { setQuizAccessToken } from '../../../../libs/services/quizAccessService';
import { createTeamQuizStat } from '../../../../libs/redux/actions/quizStatActions';
import * as Yup from 'yup';

const useStartForm = ({ quiz }: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const onSubmit = (params: any, { setSubmitting }: any) => {
    dispatch(createTeamQuizStat(params))
      .then((payload: any) => {
        setQuizAccessToken(quiz.slug, payload.token);
        redirectToNextStep(payload);
      })
      .catch(({ message }: any) => {
        setSubmitting(false);
        toast.error(message);
      });
  };

  const redirectToNextStep = ({ token, quiz_stat }: any) => {
    const surveySlug = quiz_stat.survey_stats[0].quiz_survey.slug;
    const uri = `/${quiz.slug}/${surveySlug}?token=${token}`;

    navigate(uri);
  };

  const form = useFormik({
    initialValues: {
      quiz_id: quiz.id,
      name: '',
      email: '',
      situation: [],
      partners: [],
    },
    validationSchema: Yup.object().shape({
      quiz_id: Yup.number().required(),
      name: Yup.string().required('Name is required.'),
      email: Yup.string()
        .email('Email format is invalid.')
        .required('Email is required.')
        .test('uniqueEmail', 'Email is already taken.', uniqueEmailValidator),
      situation: Yup.array(),
      partners: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required.'),
          email: Yup.string()
            .email('Email format is invalid.')
            .required('Email is required.')
            .test(
              'uniqueEmail',
              'Emails must be unique.',
              uniqueEmailValidator,
            ),
        }),
      ),
    }),
    onSubmit,
    validateOnBlur: false,
  });

  return form;
};

export default useStartForm;
