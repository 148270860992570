import { Link } from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
  return (
    <div className='page-not-found'>
      <div className='code'>404</div>
      <h1 className='message'>Oops ! Page not found.</h1>
      <Link to='/' className='btn btn-secondary w-100 mt-5'>
        Go To Home
      </Link>
    </div>
  );
};

export default PageNotFound;
