import SubmitButton from '../../SubmitButton';
import useUnsubscribeForm from '../hooks/useUnsubscribeForm';

const UnsubscribeForm = (props: any) => {
  const { form } = useUnsubscribeForm(props);

  const handleChange = (e: any, field: string) =>
    form.setFieldValue(field, e.target.checked ? 1 : 0);

  const onSubmit = (e: any) => {
    e.preventDefault(e);
    form.submitForm();
  };

  return (
    <form onSubmit={onSubmit}>
      <h3 className='mb-4'>Unsubscribe:</h3>
      <div className='mb-3'>
        <div className='form-check d-flex align-items-center'>
          <input
            id='dont_send_marketing_emails'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => handleChange(e, 'dont_send_marketing_emails')}
          />
          <label
            className='form-check-label ms-3'
            htmlFor='dont_send_marketing_emails'
          >
            I want the ability to do more quizzes in future and be emailed the
            results (keep transactional emails on SendinBlue from
            noreply@attachmentproject.com only)
          </label>
        </div>
      </div>
      <div className='mb-5'>
        <div className='form-check d-flex align-items-center'>
          <input
            id='dont_send_any_emails'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => handleChange(e, 'dont_send_any_emails')}
          />
          <label
            className='form-check-label ms-3'
            htmlFor='dont_send_any_emails'
          >
            Stop sending me all emails (SendinBlue)
          </label>
        </div>
      </div>
      <SubmitButton isSubmitting={form.isSubmitting}>Confirm</SubmitButton>
    </form>
  );
};

export default UnsubscribeForm;
