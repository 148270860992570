import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAnswer } from '../forms/question/types';
import { getComponentByKey } from '../../../libs/helpers/surveyIntroductionHelper';
import Question from '../../../interfaces/Question';
import QuestionPagination from '../../../interfaces/QuestionPagination';
import SurveyStat from '../../../interfaces/SurveyStat';
import UserAnswer from '../../../interfaces/UserAnswer';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';
import useDebounce from '../../../libs/hooks/useDebounce';

interface Props {
  isLoading: boolean;
  canStepBack: boolean;
  surveyStat: SurveyStat;
  questionPagination: QuestionPagination;
  userAnswers: UserAnswer[];
  setIsLoading: (value: boolean) => Function;
  getSurveyQuestions: (id: number, params?: object) => Promise<any>;
  completeStep: () => Promise<any>;
  skipStep: () => Promise<any>;
  startStep: () => Promise<any>;
  stepBack: () => Function;
  setNavbar: (value: any) => Function;
  setProgress: (value: number) => Function;
  createOrUpdateAnswer: (params: any) => Promise<any>;
  createOrUpdateAnswerSuccess: (payload: any) => Function;
}

const useQuizSurvey = ({
  isLoading,
  canStepBack,
  surveyStat,
  questionPagination,
  userAnswers,
  getSurveyQuestions,
  setIsLoading,
  completeStep,
  startStep,
  skipStep,
  stepBack,
  setNavbar,
  setProgress,
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage]: any = useState(searchParams.get('page'));
  const navigate = useNavigate();
  const debounce = useDebounce();

  const trackerItems = questionPagination.data.map((q) => {
    const userAnswer = userAnswers.find((x) => x.question_id === q?.id);

    return {
      value:
        userAnswer?.answer_id ||
        userAnswer?.answer_value ||
        userAnswer?.answer_text,
      required: true,
    };
  });

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: trackerItems,
    defaultLast: true,
  });

  const userAnsweredQuestionIds = new Set<number>();

  userAnswers.forEach((userAnswer) => {
    userAnsweredQuestionIds.add(userAnswer.question_id);
  });

  const IntroComponent = getComponentByKey(surveyStat.quiz_survey.introduction);

  useEffect(() => {
    if (!isLoading) {
      updateNavbar();
      updateProgress();
    }
  }, [isLoading]);

  useEffect(() => {
    const { current_page: page, last_page }: any = questionPagination;

    setPage(page);
    updateSearchParams({ page: last_page > 1 ? page : null });
  }, [questionPagination.current_page]);

  useEffect(() => {
    if (!showIntroComponent()) {
      fetchSurveyQuestions({ page });
    }
  }, [surveyStat.quiz_survey]);

  useEffect(() => {
    if (hasIntro() && isStarted()) {
      fetchSurveyQuestions({ page });
    }
  }, [surveyStat.is_started]);

  useEffect(() => {
    const notStarted = !isStarted();
    const hasIntroPage = hasIntro();

    if (notStarted && !hasIntroPage) {
      start();
    }

    if (notStarted && hasIntroPage) {
      setIsLoading(false);
    }
  }, [surveyStat.id]);

  const fetchSurveyQuestions = (params = {}) => {
    setIsLoading(true);
    getSurveyQuestions(surveyStat.id, params)
      .catch(({ message }) => {
        toast.error(message);
      })
      .finally(() => {
        setQuestionIndex(-1);
        setHasErrors(false);
        setIsLoading(false);
      });
  };

  const submit = () => {
    if (validate()) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
      return;
    }

    if (isLastPage()) {
      setIsSubmitting(true);
      setPage(1);
      completeStep()
        .then(() => setIsLoading(true))
        .catch((e) => toast.error(e.message))
        .finally(() => setIsSubmitting(false));
    } else {
      fetchSurveyQuestions({ page: questionPagination.current_page + 1 });
    }
  };

  const submitAnswer = (
    question: Question,
    { answer_id, answer_value, answer_text }: IAnswer,
  ) => {
    const params = {
      survey_stat_id: surveyStat.id,
      question_id: question.id,
      answer_id,
      answer_value,
      answer_text,
    };

    createOrUpdateAnswerSuccess(params);

    debounce(
      `question-${question.id}`,
      () => {
        createOrUpdateAnswer(params).catch(({ message }) => {
          toast.error(message);
        });
      },
      200,
    );
  };

  const skip = () => {
    setIsLoading(true);
    return skipStep().catch(({ message }) => {
      setIsLoading(false);
      toast.error(message);
    });
  };

  const start = () => {
    setIsLoading(true);
    return startStep();
  };

  const canGoBack = () => {
    return canStepBack || questionPagination.current_page > 1;
  };

  const goBack = () => {
    const { current_page } = questionPagination;

    if (current_page > 1) {
      fetchSurveyQuestions({ page: current_page - 1 });
    } else {
      setIsLoading(true);
      setPage(null);
      stepBack();
    }
  };

  const validate = () => {
    return questionPagination.data.every(
      (question: Question, index: number) => {
        if (!validateQuestion(question)) {
          setQuestionIndex(index);
          return false;
        }
        return true;
      },
    );
  };

  const validateQuestion = (question: Question) => {
    return question.skippable || userAnsweredQuestionIds.has(question.id);
  };

  const isLastPage = () =>
    questionPagination.last_page === questionPagination.current_page;

  const canContinueLater = (): boolean => {
    const { current_page } = questionPagination;
    const { is_continue_later_enabled, continue_later_step } =
      surveyStat.quiz_survey;

    return is_continue_later_enabled && current_page >= continue_later_step;
  };

  const updateSearchParams = (params = {}) => {
    const sParams = new URLSearchParams(searchParams);

    Object.entries(params).forEach(([key, value]: [string, any]) => {
      if (value) {
        sParams.set(key, value);
      } else {
        sParams.delete(key);
      }
    });

    setSearchParams(sParams.toString());
  };

  const updateNavbar = () => {
    const leftAction = canGoBack()
      ? {
          content: 'Back',
          onClick: goBack,
        }
      : { content: 'Cancel', onClick: () => navigate('/') };

    const rightAction = canSkip()
      ? {
          content: 'Skip',
          onClick: skip,
          props: { disabled: isLoading },
        }
      : null;

    setNavbar({ leftAction, rightAction });
  };

  const updateProgress = () => {
    const { current_page, last_page } = questionPagination;

    if (current_page && last_page) {
      setProgress(((current_page - 1) / last_page) * 100);
    }
  };

  const canSkip = () => isSkippable() || isPartnerSkippable();

  const isSkippable = (): boolean => {
    return surveyStat?.quiz_survey?.is_skippable === 1;
  };

  const isPartnerSkippable = (): boolean => {
    return surveyStat?.is_partner_skippable === 1;
  };

  const isStarted = () => surveyStat.is_started === 1;

  const hasIntro = () => IntroComponent !== null;

  const showIntroComponent = () => !isStarted() && hasIntro();

  const getIntroProps = () => ({
    skippable: canSkip(),
    skip,
    start,
  });

  return {
    isLoading,
    isSubmitting,
    hasErrors,
    validateQuestion,
    surveyStat,
    questionPagination,
    submit,
    submitAnswer,
    questionIndex,
    setQuestionIndex,
    canContinueLater,
    userAnswers,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
  };
};

export default useQuizSurvey;
