import axiosAuthInstance from './axios/authInstance';
import axiosGuestInstance from './axios/guestInstance';
import { handleError, handleResponse } from './utils';

const baseUrl = '/quiz';

export function getQuizBySlug(slug, params) {
  return axiosGuestInstance
    .get(`${baseUrl}/${slug}`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function getQuizSurveyQuestions(id, params) {
  return axiosAuthInstance
    .get(`${baseUrl}/${id}/questions`, { params })
    .then(handleResponse)
    .catch(handleError);
}
