import * as Sentry from '@sentry/react';

const successCodes = Array.from({ length: 100 }, (_, i) => 200 + i);
const sentryExcludedCodes = [400, 401, 403, 404, 422, 429];

export async function handleResponse(response) {
  if (successCodes.includes(response.status)) {
    return response.data;
  }

  return response;
}

export function handleError(error) {
  const errorCode = error?.response?.status || error?.code;

  if (!sentryExcludedCodes.includes(errorCode)) {
    Sentry.captureException(error);
  }

  throw error?.response?.data || error;
}
