import React from 'react';

interface Props {
  error?: string;
  show?: boolean;
}

const ValidationError: React.FC<Props> = ({ error, show = true }) => {
  return (
    <span className={`invalid-feedback ${show ? 'd-block' : 'd-none'}`}>
      {error}
    </span>
  );
};

export default ValidationError;
