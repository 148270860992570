import { Link } from 'react-router-dom';
import Layout from '../../components/layout/MainLayout';
import { ReactComponent as BackgroundSVG } from '../../assets/images/shapes.svg';
import './HomePage.css';

const HomePage = () => {
  return (
    <Layout showVisitSite={true}>
      <div className='home-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <h1 className='title'>Personal Report</h1>
              <p>
                Discover your Attachment Style in 5 minutes
                <br />
                Dot Point 2
                <br />
                Dot Point 3
              </p>
              <Link
                to='/workplace-individual/situation'
                className='btn btn-secondary mt-2'
              >
                Start Quiz
              </Link>
            </div>
          </div>
        </div>
        <BackgroundSVG
          className='background'
          color='var(--color-brand-main-700)'
        />
      </div>
    </Layout>
  );
};

export default HomePage;
