import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createQuizStat } from '../../../../libs/redux/actions/quizStatActions';
import { setQuizAccessToken } from '../../../../libs/services/quizAccessService';
import * as Yup from 'yup';

const useStartForm = ({ quiz }: any) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const onSubmit = (params: any, { setSubmitting }: any) => {
    dispatch(createQuizStat(params))
      .then((payload: any) => {
        setQuizAccessToken(quiz.slug, payload.token);
        redirectToNextStep(payload);
      })
      .catch(({ message }: any) => {
        setSubmitting(false);
        toast.error(message);
      });
  };

  const redirectToNextStep = ({ token, quiz_stat }: any) => {
    const surveySlug = quiz_stat.survey_stats[0].quiz_survey.slug;
    const uri = `/${quiz.slug}/${surveySlug}?token=${token}`;

    navigate(uri);
  };

  const form = useFormik({
    initialValues: {
      quiz_id: quiz.id,
      situation: [],
    },
    validationSchema: Yup.object().shape({
      quiz_id: Yup.number().required(),
      situation: Yup.array(),
    }),
    onSubmit,
    validateOnBlur: false,
  });

  return form;
};

export default useStartForm;
