import React from 'react';
import TilePicker from '../../../tile-picker/TilePicker';
import { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';
import { ReactComponent as OfficeIcon } from '../../../../assets/icons/office.svg';
import { ReactComponent as HybridIcon } from '../../../../assets/icons/hybrid.svg';
import { ReactComponent as RemoteIcon } from '../../../../assets/icons/remote.svg';

const iconMap: any = {
  office: OfficeIcon,
  hybrid: HybridIcon,
  remote: RemoteIcon,
};

const TileInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const questionAnswers = question.answers || [];
  const options: any = questionAnswers.map(({ id, name, key }) => {
    const Icon = key ? iconMap[key] : null;

    return {
      value: id,
      label: name,
      icon: Icon ? <Icon /> : null,
    };
  });

  const selectedOption = options.find(
    ({ value }: any) => userAnswer?.answer_id === value,
  );

  const setOption = ({ value }: IOption) =>
    onChange({ answer_id: value, answer_value: null });

  return (
    <TilePicker
      options={options}
      option={selectedOption}
      setOption={setOption}
    />
  );
};

export default TileInput;
