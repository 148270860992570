import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/workplace-individual/QuizResult';
import image from '../../assets/images/report/superpowers.png';

export class WorkplaceIndividual implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score = 50, redirectUrl }: any) {
    return {
      subtitle: 'Your Workplace Attachment Score:',
      title: `Lorem ipsum dolor`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      bulletPoints: [
        'Free 6 Page Report with your results.',
        'Get a complete breakdown of your superpowers: Safety Net, Third Eye, Calming Force, Love Rays & Star Support.',
        'Understand the Science behind the Superpowers.',
      ],
      color: 'var(--color-brand-main-900)',
      redirectUrl,
      image,
    };
  }
}
