import React, { useEffect, useState } from 'react';
import useDebounce from '../../libs/hooks/useDebounce';
import './RadioList.css';

interface Option {
  key?: string;
  label: string;
  value: any;
}

interface RadioListProps {
  name: string;
  options: Option[];
  selectedOption?: Option | undefined | null;
  typing?: boolean;
  typingLabel?: string;
  typingDebounce?: number;
  onChange: (option: Option | null) => any;
}

export const KEY_TYPING = 'typing';

const RadioList: React.FC<RadioListProps> = ({
  name,
  options,
  selectedOption,
  onChange,
  typing = false,
  typingLabel = 'Let me type',
  typingDebounce = 800,
}) => {
  const [isTypingSelected, setIsTypingSelected] = useState(false);
  const debounce = useDebounce();

  useEffect(() => {
    if (isTypingSelected) {
      onChange({ label: typingLabel, value: '', key: KEY_TYPING });
    }
  }, [isTypingSelected]);

  const optionClickHandler = (option: Option) => {
    debounce(
      name,
      () => {
        setIsTypingSelected(false);
        onChange(option);
      },
      0,
    );
  };

  const onTypeHandler = (e: any) => {
    debounce(
      name,
      () =>
        onChange({
          label: typingLabel,
          value: e.target.value,
          key: KEY_TYPING,
        }),
      typingDebounce,
    );
  };

  return (
    <div className='radio-list'>
      {options.map((option: Option, index) => {
        const isSelected = option.value === selectedOption?.value;

        return (
          <div
            key={`${name}-${index}`}
            className={`card mb-3 ${isSelected ? 'selected' : ''}`}
            onClick={() => optionClickHandler(option)}
          >
            <div className='card-body px-4'>
              <input
                name={name}
                type='radio'
                id={`radio_${name}_${index}`}
                value={option.value}
                checked={isSelected}
                onChange={() => {}}
              />
              <label htmlFor={`radio_${name}_${index}`}>{option.label}</label>
            </div>
          </div>
        );
      })}
      {typing && (
        <div
          className={`card mb-3 ${isTypingSelected ? 'selected' : ''}`}
          onClick={() => setIsTypingSelected(true)}
        >
          <div className='card-body px-4'>
            <input
              name={name}
              type='radio'
              id='let_me_type'
              checked={isTypingSelected}
              onChange={() => setIsTypingSelected(true)}
            />
            <label htmlFor='let_me_type'>{typingLabel}</label>
          </div>
        </div>
      )}
      {isTypingSelected && (
        <input
          type='text'
          className='form-control'
          placeholder='Type here...'
          onChange={onTypeHandler}
        />
      )}
    </div>
  );
};

export default RadioList;
