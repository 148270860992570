import React from 'react';
import DatePicker from '../../../datepicker/DatePicker';
import { InputProps } from './types';

const DateInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const value = userAnswer?.answer_value;

  const changeHandler = (answer_value: string) => onChange({ answer_value });

  return (
    <DatePicker
      placeholder={question.description}
      setValue={changeHandler}
      value={value}
    />
  );
};

export default DateInput;
