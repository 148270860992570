import { useEffect, useRef } from 'react';

const useDebounce = () => {
  const timeoutRef = useRef<any>([]);

  useEffect(() => {
    return () => {
      Object.values(timeoutRef.current).forEach(
        (id: any) => id && clearTimeout(id),
      );
    };
  }, []);

  const debounce = (
    identifier: string,
    callback: () => void,
    delay: number,
  ) => {
    if (timeoutRef.current[identifier]) {
      clearTimeout(timeoutRef.current[identifier]);
    }

    timeoutRef.current[identifier] = setTimeout(() => {
      callback();
      timeoutRef.current[identifier] = null;
    }, delay);
  };

  return debounce;
};

export default useDebounce;
