import Header from '../header/Header';
import Navbar from '../quiz/navbar/Navbar';
import Footer from '../footer/Footer';
import ProgressBar from '../quiz/progress-bar/ProgressBar';
import ScrollToTopButton from '../scroll-to-top-button/ScrollToTopButton';
import './QuizLayout.css';

const QuizLayout: React.FC<Props> = ({
  header = {},
  proggres,
  navbar,
  children,
  showFooter = true,
}) => {
  return (
    <div className='quiz-layout'>
      {header && <Header {...header} />}
      {proggres && <ProgressBar {...proggres} />}
      {navbar && <Navbar {...navbar} />}
      <div className='container'>{children}</div>
      {showFooter && <Footer />}
      <ScrollToTopButton />
    </div>
  );
};

export interface HeaderProps {
  title?: string;
  showVisitSite?: boolean;
}

export interface ProgressProps {
  step: number;
  totalSteps: number;
  title: string;
  percentage: number;
}

export interface Props {
  header?: HeaderProps;
  proggres?: ProgressProps;
  navbar?: any;
  children: any;
  showFooter?: boolean;
}

export default QuizLayout;
