import * as types from './types';
import * as quizApi from '../../api/quizApi';

export function loadQuizSuccess(payload) {
  return { type: types.LOAD_QUIZ_SUCCESS, payload };
}

export function invalidateQuizState() {
  return { type: types.INVALIDATE_QUIZ_STATE };
}

export function getQuizBySlug(slug, params = {}) {
  return function (dispatch) {
    return quizApi.getQuizBySlug(slug, params).then((quiz) => {
      dispatch(loadQuizSuccess(quiz));
      return quiz;
    });
  };
}
