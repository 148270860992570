import React from 'react';
import './ProgressBar.css';

interface Props {
  step: number;
  totalSteps: number;
  title: string;
  percentage: number;
}

const ProgressBar: React.FC<Props> = ({
  step,
  totalSteps,
  title,
  percentage,
}) => {
  return (
    <div className='quiz-progress-bar'>
      <div className='container py-2 fs-14'>
        <small className='fs-14'>
          {step} of {totalSteps} steps
        </small>
        <div className='d-flex align-items-center justify-content-between'>
          <div>{title}</div>
          <small className='fs-14'>{percentage}%</small>
        </div>
      </div>
      <div className='progress'>
        <div
          className='progress-bar'
          role='progressbar'
          style={{ width: `${percentage}%` }}
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
