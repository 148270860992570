import { ReactElement } from 'react';
import './TilePicker.css';

interface Option {
  icon: ReactElement;
  value: any;
  label: string;
}

interface Props {
  option?: Option;
  setOption?: (option: Option) => void;
  options?: Option[];
  className?: string;
}

const TilePicker: React.FC<Props> = ({
  option,
  setOption = () => {},
  options = [],
  className = '',
}) => {
  return (
    <div className={`tile-picker flex-wrap flex-sm-nowrap ${className}`}>
      {options.map((opt: Option) => (
        <div
          key={opt.value}
          className={`card ${option?.value === opt.value ? 'selected' : ''}`}
          onClick={() => setOption(opt)}
        >
          <div className='card-body'>
            {opt.icon}
            <span className='mt-2'>{opt.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TilePicker;
