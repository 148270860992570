import * as types from '../actions/types';
import initialState from './initialState';

export default function quizReducer(state = initialState.quiz, action) {
  switch (action.type) {
    case types.LOAD_QUIZ_SUCCESS:
      return action.payload;
    case types.INVALIDATE_QUIZ_STATE:
    default:
      return state;
  }
}
