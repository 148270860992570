export const LOAD_QUIZ_SUCCESS = 'LOAD_QUIZ_SUCCESS';
export const INVALIDATE_QUIZ_STATE = 'INVALIDATE_QUIZ_STATE';

export const LOAD_QUIZ_STAT_SUCCESS = 'LOAD_QUIZ_STAT_SUCCESS';
export const CREATE_QUIZ_STAT_SUCCESS = 'CREATE_QUIZ_STAT_SUCCESS';
export const UPDATE_QUIZ_STAT_SUCCESS = 'UPDATE_QUIZ_STAT_SUCCESS';
export const INVALIDATE_QUIZ_STAT_STATE = 'INVALIDATE_QUIZ_STAT_STATE';

export const START_SURVEY_STAT_SUCCESS = 'START_SURVEY_STAT_SUCCESS';
export const COMPLETE_SURVEY_STAT_SUCCESS = 'COMPLETE_SURVEY_STAT_SUCCESS';
export const SKIP_SURVEY_STAT_SUCCESS = 'SKIP_SURVEY_STAT_SUCCESS';

export const LOAD_SURVEY_QUESTIONS_SUCCESS = 'LOAD_SURVEY_QUESTIONS_SUCCESS';
export const INVALIDATE_SURVEY_QUESTIONS_STATE =
  'INVALIDATE_SURVEY_QUESTIONS_STATE';

export const CREATE_OR_UPDATE_ANSWER_SUCCESS =
  'CREATE_OR_UPDATE_ANSWER_SUCCESS';
export const CREATE_OR_UPDATE_ANSWER_FAILED = 'CREATE_OR_UPDATE_ANSWER_FAILED';
