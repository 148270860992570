import SendReportForm from '../forms/SendReportForm';
import './SendReportCard.css';

interface Props {
  imageSrc?: string;
  bulletPoints?: string[];
  onSendReportSuccess: (params: any) => void;
}

const SendReportCard: React.FC<Props> = ({
  imageSrc,
  bulletPoints = [],
  onSendReportSuccess,
}) => {
  return (
    <div className='send-report'>
      <div className='header'>
        {imageSrc && (
          <img src={imageSrc} alt='Report' className='cover-image' />
        )}
        <h4 className='title'>
          Get the full PDF report sent straight to your email.
        </h4>
      </div>
      {bulletPoints.length > 0 && (
        <ul className='dot-points mb-4'>
          {bulletPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      )}
      <SendReportForm onSuccessCallback={onSendReportSuccess} />
    </div>
  );
};

export default SendReportCard;
