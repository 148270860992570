import { handleError, handleResponse } from './utils';
import axiosAuthInstance from './axios/authInstance';

const baseUrl = '/survey-stat';

export function start(id, params) {
  return axiosAuthInstance
    .put(`${baseUrl}/${id}/start`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function complete(id, params) {
  return axiosAuthInstance
    .put(`${baseUrl}/${id}/complete`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function skip(id, params) {
  return axiosAuthInstance
    .put(`${baseUrl}/${id}/skip`, params)
    .then(handleResponse)
    .catch(handleError);
}
