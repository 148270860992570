import React, { useEffect } from 'react';
import { ReactComponent as SquareMinusIcon } from '../../../assets/icons/square-minus.svg';
import { ReactComponent as PlusCircleIcon } from '../../../assets/icons/plus-circle.svg';
import ValidationError from '../../ValidationError';

const PartnerInfoForm = ({
  form,
  partnerMin = 1,
  partnerMax = 10,
  titlePrefix = 'Partner',
  addButtonText = 'Add more partners',
}: any) => {
  const canAddPartner = () => partnerMax > form.values.partners.length;

  const addPartners = (length = 1) => {
    const newPartners = Array.from({ length }, () => ({
      name: '',
      email: '',
    }));

    form.setFieldValue('partners', [...form.values.partners, ...newPartners]);
  };

  const removePartner = (index: number) => {
    const partners = form.values.partners;
    partners.splice(index, 1);
    form.setFieldValue('partners', partners);
  };

  const getFieldProps = (index: number, field: string) => ({
    className: `form-control ${
      isInvalidField(index, field) ? 'is-invalid' : ''
    }`,
    ...form.getFieldProps(`partners[${index}].${field}`),
  });

  const isInvalidField = (index: number, field: string) => {
    return (
      form.touched?.partners?.[index]?.[field] !== undefined &&
      form.errors?.partners?.[index]?.[field] !== undefined
    );
  };

  useEffect(() => {
    form.setFieldValue('partners', form.values.partners.slice(0, partnerMax));
  }, [partnerMax]);

  useEffect(() => {
    const partnersToAdd = Math.max(0, partnerMin - form.values.partners.length); 

    if (partnersToAdd > 0) {
      addPartners(partnersToAdd);
    }
  }, [partnerMin]);

  return form.values.partners.map((_: any, index: number) => (
    <React.Fragment key={index}>
      <div>
        <div className='d-flex align-items-center'>
          {partnerMax > 1 && (
            <h6>
              {titlePrefix} {index + 1}
            </h6>
          )}
          {index >= partnerMin && (
            <button
              type='button'
              className='btn btn-link ms-auto mb-1 p-0 fs-12'
              onClick={() => removePartner(index)}
            >
              Remove
              <SquareMinusIcon className='ms-2' />
            </button>
          )}
        </div>
        <div className='mb-3'>
          <input
            autoComplete='none'
            placeholder='Name'
            {...getFieldProps(index, `name`)}
          />
          {isInvalidField(index, `name`) && (
            <ValidationError error={form.errors.partners[index].name} />
          )}
        </div>
      </div>
      <div className='mb-3'>
        <input
          autoComplete='none'
          placeholder='Email'
          {...getFieldProps(index, `email`)}
        />
        {isInvalidField(index, `email`) && (
          <ValidationError error={form.errors.partners[index].email} />
        )}
      </div>
      {index === form.values.partners.length - 1 && (
        <div className='d-flex align-items-center'>
          {canAddPartner() && (
            <button
              type='button'
              className='btn btn-link d-flex align-items-center px-0 py-1'
              onClick={() => addPartners(1)}
            >
              <PlusCircleIcon className='me-2' />
              {addButtonText}
            </button>
          )}
          {partnerMax > 1 && (
            <small className='ms-auto'>
              {form.values.partners.length} of {partnerMax}
            </small>
          )}
        </div>
      )}
    </React.Fragment>
  ));
};

export default PartnerInfoForm;
