import checkmarkImg from '../../assets/images/checkmark.webp';
import styles from './success.message.module.css';


const SuccessMessage = ({ children, ...props }: any) => {
  return (
    <div className={styles.wrapper} {...props}>
      <img src={checkmarkImg} alt='Success' className={styles.image} />
      <h2 className={styles.content}>{children}</h2>
    </div>
  );
};

export default SuccessMessage;
