import { handleError, handleResponse } from './utils';
import axiosGuestInstance from './axios/guestInstance';

const baseUrl = '/team-quiz-stat';

export function createQuizStat(params) {
  return axiosGuestInstance
    .post(baseUrl, params)
    .then(handleResponse)
    .catch(handleError);
}