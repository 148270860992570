import { combineReducers } from 'redux';
import quiz from './quizReducer';
import quizStat from './quizStatReducer';
import surveyQuestions from './surveyQuestionsReducer';

const rootReducer = combineReducers({
  quiz,
  quizStat,
  surveyQuestions,
});

export default rootReducer;
