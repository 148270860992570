import axios from 'axios';
import env from '../../../env';

const instance = axios.create({
  baseURL: env.apiUrl,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

export default instance;
