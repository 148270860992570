import ReactDOM from 'react-dom';
import { useEffect, useRef } from 'react';
import { Modal as BootstrapModal } from 'bootstrap';

const Modal = ({
  id = 'modal',
  size = 'lg',
  toggleBtn = null,
  toggleProps = {},
  children,
  isOpened,
  setIsOpened,
  ...props
}) => {
  const instance = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener('hide.bs.modal', handleModalHide);
      instance.current = BootstrapModal.getOrCreateInstance(modalRef.current);
    }

    if (instance.current) {
      isOpened ? instance.current.show() : instance.current.hide();
    }

    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener('hide.bs.modal', handleModalHide);
      }
      
      if (instance.current) {
        instance.current && instance.current.hide();
      }
    };
  }, [isOpened]);

  const handleModalHide = () => setIsOpened(false);

  return (
    <>
      {toggleBtn && (
        <div onClick={() => setIsOpened(!isOpened)} {...toggleProps}>
          {toggleBtn}
        </div>
      )}

      {isOpened &&
        ReactDOM.createPortal(
          <div id={id} ref={modalRef} className='modal fade' {...props}>
            <div
              className={`modal-dialog modal-dialog-centered ${`modal-${size}`}`}
            >
              <div className='modal-content'>{children}</div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

Modal.Header = ({
  modalId = 'modal',
  showCloseButton = true,
  children,
  ...props
}) => {
  return (
    <div className='modal-header' {...props}>
      {children}
      {showCloseButton && (
        <button
          type='button'
          className='btn-close btn-sm'
          data-bs-dismiss={modalId}
          aria-label='Close'
        />
      )}
    </div>
  );
};

Modal.Body = ({ children, ...props }) => (
  <div className='modal-body' {...props}>
    {children}
  </div>
);

Modal.Footer = ({ children, ...props }) => (
  <div className='modal-footer' {...props}>
    {children}
  </div>
);

Modal.CancelButton = ({ modalId = 'modal', children = 'Cancel', ...props }) => (
  <button
    type='button'
    className='btn btn-secondary'
    data-bs-dismiss={modalId}
    {...props}
  >
    {children}
  </button>
);

Modal.ActionButton = ({ children = 'Save', ...props }) => (
  <button type='button' className='btn btn-primary' {...props}>
    {children}
  </button>
);

export default Modal;
