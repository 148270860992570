import { IAnswer } from '../../components/quiz/forms/question/types';
import Question from '../../interfaces/Question';
import UserAnswer from '../../interfaces/UserAnswer';
import * as answerTypes from './answerTypeHelper';

export const FREE_TEXT = 'text';
export const MULTI_CHOICE = 'multi-choice';
export const SELECT = 'select';
export const DATE = 'date';
export const TILE_PICKER = 'tile-picker';
export const LOCATION_PICKER = 'location-picker';

export const getQuestionAnswer = (
  question: Question,
  userAnswers: UserAnswer[],
): IAnswer | null => {
  const userAnswer = userAnswers.find((x) => x.question_id === question.id);

  if (!userAnswer) {
    return null;
  }

  const answer = question.answers.find((x) => userAnswer?.answer_id === x.id);
  const questionType = question.survey_question_type?.key;
  const answerIdPopulated = userAnswer.answer_id;
  const valuePopulated =
    userAnswer.answer_value && userAnswer.answer_value.trim().length > 0;
  const textPopulated =
    userAnswer.answer_text &&
    (typeof userAnswer.answer_text !== 'string' ||
      userAnswer.answer_text.trim().length > 0);

  switch (questionType) {
    case FREE_TEXT:
    case DATE:
      return valuePopulated ? userAnswer : null;
    case TILE_PICKER:
      return answerIdPopulated ? userAnswer : null;
    case LOCATION_PICKER:
      return textPopulated ? userAnswer : null;
    case MULTI_CHOICE:
      if (answer?.type === answerTypes.FREE_TEXT && !valuePopulated) {
        return null;
      }

      return answerIdPopulated ? userAnswer : null;
    default:
      return userAnswer;
  }
};
