import React from 'react';
import CountryStatePicker from '../../../CountryStatePicker';
import { InputProps, IAnswer } from './types';

const LocationInput: React.FC<InputProps> = ({ userAnswer, onChange }) => {
  const answerValue = userAnswer?.answer_text;
  const selectedCountry = answerValue?.country || null;
  const selectedState = answerValue?.state || null;

  const onChangeHandler = ({
    isGeolocated,
    isStateAvailable,
    country,
    state,
  }: any) => {
    const iAnswer: IAnswer = { answer_text: null };

    if ((country && !isStateAvailable) || (country && state)) {
      Object.assign(iAnswer, { answer_text: { country, state } });
    }

    onChange(iAnswer, isGeolocated);
  };

  return (
    <CountryStatePicker
      onChange={onChangeHandler}
      selectedCountry={selectedCountry}
      selectedState={selectedState}
      shouldGeolocate={!userAnswer}
    />
  );
};

export default LocationInput;
