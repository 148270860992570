import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getQuizBySlug,
  invalidateQuizState,
} from '../../../libs/redux/actions/quizActions';
import * as quizHelper from '../../../libs/helpers/quizHelper';
import Quiz from '../../../interfaces/Quiz';
import Loader from '../../../components/quiz/loader/Loader';
import StartRegularQuiz from './regular/StartQuizPage';
import StartTeamQuiz from './team/StartQuizPage';

interface Props {
  quiz: Quiz;
  getQuizBySlug: (slug: string) => Promise<any>;
  invalidateQuizState: () => any;
}

const StartQuizPage: React.FC<Props> = ({ quiz, getQuizBySlug }) => {
  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const { slug = '' } = useParams();
  const navigate = useNavigate();

  const isRegularMode = () => quiz.mode === quizHelper.MODE_SINGLE;

  const isTeamMode = () => quiz.mode === quizHelper.MODE_TEAM;

  useEffect(() => {
    fetchQuiz();

    return () => {
      invalidateQuizState();
    };
  }, []);

  const fetchQuiz = () => {
    getQuizBySlug(slug)
      .then(() => setIsLoading(false))
      .catch(() => navigate('/'));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isRegularMode() && <StartRegularQuiz />}
      {isTeamMode() && <StartTeamQuiz />}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {
  getQuizBySlug,
  invalidateQuizState,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
