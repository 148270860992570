import React from 'react';
import { connect } from 'react-redux';
import { getImageSrc } from '../../../libs/helpers/imageHelper';
import { getSurveyQuestions } from '../../../libs/redux/actions/surveyQuestionsActions';
import {
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
} from '../../../libs/redux/actions/userAnswerActions';
import { IAnswer } from '../forms/question/types';
import QuestionContainer from '../../question-container/QuestionContainer';
import ValidationError from '../../ValidationError';
import ContinueLaterModal from '../ContinueLaterModal';
import SubmitButton from '../../SubmitButton';
import QuestionForm from '../forms/question/QuestionForm';
import useQuizSurvey from './useQuizSurvey';
import './QuizSurvey.css';

const QuizSurvey: React.FC<any> = (props) => {
  const {
    isLoading,
    isSubmitting,
    hasErrors,
    validateQuestion,
    surveyStat,
    questionPagination,
    submit,
    submitAnswer,
    questionIndex,
    setQuestionIndex,
    canContinueLater,
    userAnswers,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
  } = useQuizSurvey(props);

  const quizSurvey: any = surveyStat.quiz_survey;
  const survey: any = quizSurvey.survey;
  const isImmutable = quizSurvey.is_immutable;
  const { current_page, per_page, total } = questionPagination;

  const getClassName = () => {
    const classNames = ['quiz-survey'];

    if (isLoading) {
      classNames.push('loading');
    }

    if (isImmutable) {
      classNames.push('immutable');
    }

    return classNames.join(' ');
  };

  return (
    <div className={getClassName()}>
      {showIntroComponent() ? (
        <IntroComponent {...getIntroProps()} />
      ) : (
        <React.Fragment>
          <div className='header'>
            <div className='d-flex flex-wrap align-items-center mb-4'>
              {survey.image && (
                <img
                  src={getImageSrc(survey.image)}
                  alt='Survey'
                  className='image'
                />
              )}
              <h3 className='title'>{survey.name}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: survey.description }} />
          </div>
          <hr className='my-5' />
          <QuestionContainer
            autoHighlight={!isImmutable}
            focusIndex={questionIndex}
            setFocusIndex={setQuestionIndex}
            totalCount={total}
            countFrom={(current_page - 1) * per_page + 1}
            questions={questionPagination.data.map((question) => {
              const { id, name: title, description } = question;
              const userAnswer: any = userAnswers.find(
                (x: any) => x.question_id === id,
              );
              const isAnswered = userAnswer !== undefined;

              return {
                title,
                description,
                isAnswered,
                children: (
                  <React.Fragment>
                    <QuestionForm
                      question={question}
                      userAnswer={userAnswer}
                      onChange={(answer: IAnswer) =>
                        submitAnswer(question, answer)
                      }
                    />
                    {hasErrors && !validateQuestion(question) && (
                      <div className='validation-error'>
                        <ValidationError error='Please select your answer.' />
                      </div>
                    )}
                  </React.Fragment>
                ),
              };
            })}
          />
          <div className='action-wrapper'>
            <div className='d-flex flex-wrap-reverse flex-sm-nowrap align-items-center justify-content-center'>
              {canContinueLater() ? (
                <ContinueLaterModal className='btn btn-light w-100 me-0 me-sm-3 mt-3 mt-sm-0'>
                  Continue later
                </ContinueLaterModal>
              ) : (
                ''
              )}
              <SubmitButton onClick={submit} isSubmitting={isSubmitting}>
                Continue
              </SubmitButton>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = ({ surveyQuestions }: any) => {
  return {
    questionPagination: surveyQuestions.questions,
    userAnswers: surveyQuestions.userAnswers,
  };
};

const mapDispatchToProps = {
  getSurveyQuestions,
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizSurvey);
