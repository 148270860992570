import React, { useEffect, useState } from 'react';
import { ReactComponent as DotIcon } from '../../assets/icons/dot.svg';
import './RangeSlider.css';

export interface IOption {
  label: string;
  label_html?: string | TrustedHTML;
  value: any;
}

interface Props {
  options: IOption[];
  selectedOption?: IOption;
  placeholder?: string;
  step?: number;
  setOption: (option: IOption) => void;
}

const RangeSlider: React.FC<Props> = ({
  options,
  selectedOption,
  setOption,
  placeholder = 'Slide the button',
  step = 0.1,
}) => {
  const optionsCount = options.length;

  const [valueIndex, setValueIndex]: any = useState('');

  const getValue = () => {
    if (valueIndex >= 0) {
      return valueIndex;
    }

    return optionsCount % 2 === 0 ? 0 : (optionsCount - 1) / 2;
  };

  useEffect(() => {
    const newValueIdx =
      options.findIndex((o) => o?.value === selectedOption?.value) || 0;

    setValueIndex(newValueIdx);
  }, [selectedOption]);

  const onChangeHandler = (e: any) => {
    const newValueIdx = Math.round(e.target.value);
    setValueIndex(newValueIdx);
  };

  const onMouseUpHandler = (e: any) => {
    const option = options[e.target.value];

    if (option) {
      setOption(option);
    }
  };

  const getTickWrapperStyle = () => {
    const optionWidthPerc = 100 / optionsCount;
    const widthPerc = 100 + optionWidthPerc;

    return {
      width: `${widthPerc}%`,
      margin: `0 -${optionWidthPerc / 2}%`,
    };
  };

  const getTickStyle = () => {
    return {
      minWidth: `calc(100% / ${optionsCount})`,
    };
  };

  return (
    <div className={`range-slider-wrapper ${selectedOption ? 'active' : ''}`}>
      <label>{selectedOption?.label || placeholder}</label>
      <div className='range-slider'>
        <input
          type='range'
          className='custom-range-slider'
          min={0}
          max={optionsCount - 1}
          step={step}
          onChange={onChangeHandler}
          onMouseUp={onMouseUpHandler}
          onTouchEnd={onMouseUpHandler}
          value={getValue()}
        />
        <div className='dot-wrapper'>
          {options.map((_x, index) => (
            <DotIcon key={`dot-${index}`} />
          ))}
        </div>
        <div
          className='tick-wrapper d-flex d-lg-none'
          style={getTickWrapperStyle()}
        >
          {options.map(({ label, label_html = null }, index) => (
            <div
              className='tick'
              key={`${label}-${index}`}
              dangerouslySetInnerHTML={{ __html: label_html || label }}
              style={getTickStyle()}
            />
          ))}
        </div>
        <div
          className='tick-wrapper d-none d-lg-flex'
          style={getTickWrapperStyle()}
        >
          {options.map(({ label }, index) => (
            <div
              className='tick'
              key={`${label}-${index}`}
              style={getTickStyle()}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
