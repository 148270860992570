import { handleError, handleResponse } from './utils';
import axios from './axios/guestInstance';

const baseUrl = '/user';

export function unsubscribe(token, params) {
  return axios
    .post(`${baseUrl}/${token}/unsubscribe`, params)
    .then(handleResponse)
    .catch(handleError);
}
