import HumanAttachment from '../../components/quiz/survey/intro/HumanAttachment';
import PsychologicalWellBeing from '../../components/quiz/survey/intro/PsychologicalWellBeing';
import TeamDynamics from '../../components/quiz/survey/intro/TeamDynamics';

const HUMAN_ATTACHMENT = 'human-attachment';
const TEAM_DYNAMICS = 'team-dynamics';
const PSYCH_WELL_BEING = 'psych-well-being';

const components: any = {
  [HUMAN_ATTACHMENT]: HumanAttachment,
  [TEAM_DYNAMICS]: TeamDynamics,
  [PSYCH_WELL_BEING]: PsychologicalWellBeing,
};

export const getComponentByKey = (key: string): any => {
  return components[key] ?? null;
};
