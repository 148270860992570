import * as types from './types';
import * as surveyStatApi from '../../api/surveyStatApi';

export function startSurveyStatSuccess(id) {
  return { type: types.START_SURVEY_STAT_SUCCESS, id };
}

export function completeSurveyStatSuccess(id) {
  return { type: types.COMPLETE_SURVEY_STAT_SUCCESS, id };
}

export function skipSurveyStatSuccess(id) {
  return { type: types.SKIP_SURVEY_STAT_SUCCESS, id };
}

export function startSurveyStat(id, params = {}) {
  return function (dispatch) {
    return surveyStatApi.start(id, params).then(() => {
      dispatch(startSurveyStatSuccess(id));
    });
  };
}

export function completeSurveyStat(id, params = {}) {
  return function (dispatch) {
    return surveyStatApi.complete(id, params).then(() => {
      dispatch(completeSurveyStatSuccess(id));
    });
  };
}

export function skipSurveyStat(id, params = {}) {
  return function (dispatch) {
    return surveyStatApi.skip(id, params).then(() => {
      dispatch(skipSurveyStatSuccess(id));
    });
  };
}
