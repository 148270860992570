interface Props {
  isSubmitting?: boolean;
  bsColor?: string;
  bsSpinnerColor?: string;
  className?: string;
  children?: any;
  onClick?: any;
  [key: string]: any;
}

const SubmitButton: React.FC<Props> = ({
  isSubmitting = false,
  bsColor = 'secondary',
  bsSpinnerColor = 'primary',
  className = '',
  children = 'Submit',
  ...props
}) => {
  return (
    <button
      type='submit'
      className={`btn btn-${bsColor} w-100 ${className}`}
      disabled={isSubmitting}
      {...props}
    >
      {isSubmitting ? (
        <div className={`spinner-border text-${bsSpinnerColor}`} role='status'>
          <span className='visually-hidden'>Submitting...</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default SubmitButton;
