import axios from 'axios';
import env from '../../../env';
import { getQuizSessionToken } from '../../services/quizAccessService';

const instance = axios.create({
  baseURL: env.apiUrl,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = getQuizSessionToken();

    if (token) {
      config.headers['Auth-Key-Token'] = token;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
