import { useParams } from 'react-router-dom';
import SurveyStat from '../../interfaces/SurveyStat';

const DEFAULT = 'default';

interface ConfigType {
  [key: string]: {
    [key: string]: {
      title: string;
    };
  };
}

const useOpenGraph = (surveyStat: SurveyStat) => {
  const { slug = '' } = useParams();

  const defaultQuizConfig = {
    [DEFAULT]: {
      title: 'MindOnly | Survey',
    },
  };

  const config: ConfigType = {

  };

  const quizConfig = config[slug] ?? defaultQuizConfig;

  return quizConfig[surveyStat?.quiz_survey?.slug] || quizConfig[DEFAULT];
};

export default useOpenGraph;
