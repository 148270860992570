import { useEffect, useRef, useState } from 'react';

interface Item {
  value: any;
  required?: boolean;
}

interface Props {
  items: Item[];
  defaultLast?: boolean;
}

const useIndexTracker = ({ items, defaultLast = false }: Props) => {
  const [index, setIndex] = useState(0);
  const [prevItems, setPrevItems]: any = useState(items);

  const changeString = items
    .map((item) => `${item.value}${item.required || ''}`)
    .join('');

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) return;
    const nextIndex = getNextItemIndex();

    setIndex(nextIndex);
    setPrevItems(items);
  }, [changeString]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const getNextItemIndex = () => {
    const lastUpdatedItemIndex = getLastUpdatedItemIndex();

    // If not found, check for the previous required unanswered question
    for (let i = 0; i <= lastUpdatedItemIndex; i++) {
      if (items[i]?.required && !isThruthyValue(items[i]?.value)) {
        return i;
      }
    }

    // First, check for the next unanswered question
    for (let i = lastUpdatedItemIndex; i < items.length; i++) {
      if (!isThruthyValue(items[i]?.value)) {
        return i;
      }
    }

    return defaultLast ? items.length - 1 : lastUpdatedItemIndex;
  };

  const getLastUpdatedItemIndex = () => {
    return items.findIndex((q, i) => {
      const previousQuestion = prevItems?.[i] || {};

      return q.value !== previousQuestion?.value;
    });
  };

  const isThruthyValue = (value: any) => {
    return value !== null && value !== undefined && value !== '';
  };

  return { index, setIndex };
};

export default useIndexTracker;
