import React from 'react';
import RadioList, { KEY_TYPING } from '../../../radio-list/RadioList';
import * as answerTypes from '../../../../libs/helpers/answerTypeHelper';
import { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';

const RadioInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const questionAnswers = question.answers || [];
  const typeAnswer = questionAnswers.find(
    ({ type }) => type === answerTypes.FREE_TEXT,
  );
  const typing = typeAnswer !== undefined;

  const options: IOption[] = questionAnswers
    .filter(({ type }) => type === answerTypes.REGULAR)
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const selectedOption = options.find(
    ({ value }) => userAnswer?.answer_id === value,
  );

  const changeHandler = ({ value, key }: any) => {
    if (key === KEY_TYPING) {
      onChange({ answer_id: typeAnswer?.id || null, answer_value: value });
    } else {
      onChange({ answer_id: value, answer_value: null });
    }
  };

  return (
    <RadioList
      name={`questrion_${question.id}`}
      options={options}
      typing={typing}
      typingLabel={typeAnswer?.name}
      onChange={changeHandler}
      selectedOption={selectedOption}
    />
  );
};

export default RadioInput;
