import * as geolocationApi from '../api/geolocationApi';

export const getGeolocation = () => {
  return geolocationApi
    .getGeolocation()
    .then((res) => {
      return {
        country: res['country_code'],
        state: res['region_code'],
      };
    })
    .catch(() => {})
    .finally(() => {
      return { country: null, state: null };
    });
};
