import { Link } from 'react-router-dom';
import QuizLayout from '../../../components/layout/QuizLayout';
import SuccessMessage from '../../../components/success-message/SuccessMessage';
import './LimitReachedPage.css';

const LimitReachedPage = () => {
  return (
    <QuizLayout showFooter={false} header={{ showVisitSite: true }}>
      <div className='limit-reached'>
        <SuccessMessage>
          You reached the limit for this quiz! Try again in 30 minutes
        </SuccessMessage>
        <Link to='/' className='btn btn-secondary w-100 mt-5'>
          Go To Home
        </Link>
      </div>
    </QuizLayout>
  );
};

export default LimitReachedPage;
