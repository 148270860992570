import { toast } from 'react-toastify';
import { continueLater } from '../../../libs/api/quizStatApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSuccessCallback?: (payload?: any) => void;
}

const useContinueLaterForm = ({ onSuccessCallback = () => {} }: Props) => {
  const validationSchema: any = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
      .required('Email is required.')
      .email('Email format is invalid.'),

    agree: Yup.boolean().isTrue('You have to agree to receive emails.'),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    continueLater(values)
      .then(() => onSuccessCallback(values))
      .catch(({ message }: any) => {
        toast.error(message);
        setSubmitting(false);
      });
  };

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      agree: false,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return {
    form,
  };
};

export default useContinueLaterForm;
