import Footer from '../footer/Footer';
import Header from '../header/Header';
import './MainLayout.css';

interface Props {
  children: any;
  showVisitSite?: boolean;
}

const MainLayout: React.FC<Props> = ({ showVisitSite = false, children }) => {
  return (
    <div className='main-layout'>
      <Header showVisitSite={showVisitSite} />
      <div className='content'>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
