import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImage } from '../../assets/images/logo.svg';
import env from '../../env';
import './Header.css';

interface Props {
  title?: string;
  showVisitSite?: boolean;
}

const Header: React.FC<Props> = ({ title = null, showVisitSite = false }) => {
  return (
    <header className='header'>
      <div className='container d-flex align-items-center justify-content-center justify-content-md-start fw-bolder'>
        <Link to={env.mindOnlyUrl}>
          <LogoImage />
        </Link>
        {title && <h1 className='title'>{title}</h1>}
        {showVisitSite && (
          <a
            href='https://www.mindonly.com'
            className='btn btn-secondary ms-auto'
          >
            Visit the site
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
