import { useEffect } from 'react';
import {
  Route,
  useParams,
  Routes,
  useSearchParams,
  Navigate,
} from 'react-router-dom';
import {
  getQuizAccessToken,
  quizCompletedCookieExists,
  setQuizAccessToken,
  setQuizSessionToken,
} from '../libs/services/quizAccessService';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import QuizPage from '../pages/quiz/QuizPage';
import ResultPage from '../pages/quiz/result/ResultPage';
import QuizCompletedPage from '../pages/quiz/complete/QuizCompletedPage';
import StartQuizPage from '../pages/quiz/start';

const QuizRouter = () => {
  const [searchParams, setSearchParams]: [any, any] = useSearchParams();
  const { slug = '' } = useParams();

  const isLimitReached = quizCompletedCookieExists(slug);
  const token = searchParams.get('token') || getQuizAccessToken(slug);

  useEffect(() => {
    if (!isLimitReached && token) {
      updateQuizAccessToken();
    }
  }, [token]);

  const updateQuizAccessToken = () => {
    setQuizAccessToken(slug, token);
    setQuizSessionToken(token);

    setSearchParams((prev: URLSearchParams) => {
      prev.set('token', token);
      return prev;
    });
  };

  return (
    <Routes>
      {isLimitReached ? (
        <Route path='*' element={<Navigate to='/limit-reached' />} />
      ) : token ? (
        <>
          <Route path='/completed' element={<QuizCompletedPage />} />
          <Route path='/preliminary-result' element={<ResultPage />} />
          <Route path='/:step?' element={<QuizPage />} />
        </>
      ) : (
        <>
          <Route path='/' element={<Navigate to='./situation' />} />
          <Route path='/situation' element={<StartQuizPage />} />
        </>
      )}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default QuizRouter;
