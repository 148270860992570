import * as types from '../actions/types';
import initialState from './initialState';
import { getHideOnSkipSurveyStatIds } from '../../helpers/surveyStatHelper';

export default function quizStatReducer(state = initialState.quizStat, action) {
  let skippedIds;

  switch (action.type) {
    case types.START_SURVEY_STAT_SUCCESS:
      return {
        ...state,
        survey_stats: state.survey_stats.map((x) =>
          x.id === action.id ? { ...x, is_started: 1 } : x,
        ),
      };
    case types.COMPLETE_SURVEY_STAT_SUCCESS:
      skippedIds = getHideOnSkipSurveyStatIds(action.id, state.survey_stats);
      return {
        ...state,
        survey_stats: state.survey_stats.map((x) =>
          skippedIds.includes(x.id)
            ? { ...x, is_skipped: 0 }
            : x.id === action.id
            ? { ...x, is_completed: 1, is_skipped: 0 }
            : x,
        ),
      };
    case types.SKIP_SURVEY_STAT_SUCCESS:
      skippedIds = getHideOnSkipSurveyStatIds(action.id, state.survey_stats);
      skippedIds.push(action.id);

      return {
        ...state,
        survey_stats: state.survey_stats.map((x) =>
          skippedIds.includes(x.id)
            ? { ...x, is_skipped: 1, is_completed: 0 }
            : x,
        ),
      };
    case types.LOAD_QUIZ_STAT_SUCCESS:
    case types.CREATE_QUIZ_STAT_SUCCESS:
    case types.UPDATE_QUIZ_STAT_SUCCESS:
      return action.payload;
    case types.INVALIDATE_QUIZ_STAT_STATE:
      return initialState.quizStat;
    default:
      return state;
  }
}
