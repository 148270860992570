import React from 'react';
import Answer from '../../../../interfaces/Answer';
import Select from '../../../Select';
import { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';

const SelectInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const placeholder = question.description;

  const options = question.answers.map((answer: Answer) => ({
    label: answer.name,
    value: answer.id,
  }));

  const selectedOption = options.find(
    ({ value }: any) => userAnswer?.answer_id === value,
  );

  const changeHandler = (option: IOption) => {
    const answer_id = option?.value || null;

    onChange({ answer_id });
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={changeHandler}
      menuPlacement='auto'
      placeholder={placeholder}
      isSearchable
      isClearable
    />
  );
};

export default SelectInput;
