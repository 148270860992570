import React from 'react';
import RangeSlider, { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';

const RangeInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const questionAnswers = question.answers || [];
  const options: IOption[] = questionAnswers.map(
    ({ id: value, name: label, name_html: label_html }) => ({
      label,
      label_html,
      value,
    }),
  );

  const selectedOption = options.find(
    (x) => x?.value === userAnswer?.answer_id,
  );

  const setOption = ({ value: answer_id }: IOption) => {
    onChange({ answer_id });
  };

  return (
    <RangeSlider
      options={options}
      selectedOption={selectedOption}
      setOption={setOption}
    />
  );
};

export default RangeInput;
