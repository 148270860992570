import * as types from '../actions/types';
import initialState from './initialState';

export default function surveyQuestionsReducer(
  state = initialState.surveyQuestions,
  action,
) {
  switch (action.type) {
    case types.LOAD_SURVEY_QUESTIONS_SUCCESS:
      return action.payload;
    case types.CREATE_OR_UPDATE_ANSWER_SUCCESS:
      const userAnswers = state.userAnswers.map((y) => {
        return y.question_id === action.payload.question_id
          ? action.payload
          : y;
      });

      const isUpdated = userAnswers.some(
        (y) => y.question_id === action.payload.question_id,
      );

      if (!isUpdated) {
        userAnswers.push(action.payload);
      }

      return { ...state, userAnswers };
    case types.CREATE_OR_UPDATE_ANSWER_FAILED:
      return {
        ...state,
        userAnswers: state.userAnswers.filter(
          (x) => x.question_id !== action.payload.question_id,
        ),
      };
    case types.INVALIDATE_SURVEY_QUESTIONS_STATE:
      return initialState.surveyQuestions;
    default:
      return state;
  }
}
