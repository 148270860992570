import React from 'react';
import { IAnswer } from '../forms/question/types';
import QuestionContainer from '../../question-container/QuestionContainer';
import QuestionForm from '../forms/question/QuestionForm';
import ValidationError from '../../ValidationError';
import SubmitButton from '../../SubmitButton';
import useSituation from './useSituation';

const Situation = (props: any) => {
  const {
    form,
    questionIndex,
    setQuestionIndex,
    questions,
    updateSituation,
    submitForm,
    errors,
  } = useSituation(props);

  return (
    <form onSubmit={submitForm}>
      <QuestionContainer
        autoHighlight={false}
        focusIndex={questionIndex}
        setFocusIndex={setQuestionIndex}
        questions={questions.map((question) => {
          const { id, name: title } = question;
          const userAnswer: any = form.values.situation.find(
            (x: any) => x.question_id === id,
          );
          const isAnswered = userAnswer !== undefined;
          const error = errors[id];

          return {
            title,
            isAnswered,
            children: (
              <React.Fragment>
                <QuestionForm
                  question={question}
                  userAnswer={userAnswer}
                  onChange={(asnwer: IAnswer, overrideFocus: boolean = false) =>
                    updateSituation(question, asnwer, overrideFocus)
                  }
                />
                {error && (
                  <div className='validation-error'>
                    <ValidationError error={error.message} />
                  </div>
                )}
              </React.Fragment>
            ),
          };
        })}
      />
      <div className='text-center my-5 pt-4'>
        <SubmitButton isSubmitting={form.isSubmitting}>Continue</SubmitButton>
      </div>
    </form>
  );
};

export default Situation;
