interface AppEnvConfig {
  environment: string;
  apiUrl: string;
  mindOnlyUrl: string;
  sentryDns: string;
}

const defaults: AppEnvConfig = {
  environment: 'local',
  apiUrl: 'http://api.mindonly.local/api/v1',
  mindOnlyUrl: 'https://mindonly.com/',
  sentryDns: '',
};

const env: AppEnvConfig = {
  environment: process.env.REACT_APP_ENV || defaults.environment,
  apiUrl: process.env.REACT_APP_API_URL || defaults.apiUrl,
  mindOnlyUrl: process.env.REACT_APP_MIND_ONLY_URL || defaults.mindOnlyUrl,
  sentryDns: process.env.REACT_APP_SENTRY_DNS || defaults.sentryDns,
};

export default env;
