export const getFieldProps = (form, field) => {
  const invalid = isInvalidField(form, field);

  return {
    className: `form-control ${invalid ? 'is-invalid' : ''}`,
    ...form.getFieldProps(field),
  };
};

export const isInvalidField = (form, field) => {
  return form.touched[field] !== undefined && form.errors[field] !== undefined;
};
