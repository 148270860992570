import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getResults } from '../../../libs/api/quizStatApi';
import { getQuizModel } from '../../../libs/helpers/quizHelper';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
  setQuizCompletedCookie,
} from '../../../libs/services/quizAccessService';
import PageNotFound from '../../page-not-found/PageNotFound';
import env from '../../../env';

const useResultPage = () => {
  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const [results, setResults]: [any, any] = useState({});

  const { slug = '' } = useParams();
  const navigate = useNavigate();

  const quizModel = getQuizModel(slug);

  useEffect(() => {
    getResults()
      .then((payload) => {
        setResults(payload);
        setIsLoading(false);
      })
      .catch(({ code }) => {
        removeQuizTokens();

        code === 403 ? navigate(`/${slug}`) : navigate('/page-not-found');
      });
  }, []);

  const removeQuizTokens = () => {
    removeQuizAccessToken(slug);
    removeQuizSessionToken();
  };

  const onSendReportSuccess = (props: any = {}) => {
    removeQuizTokens();
    setQuizCompletedCookie(slug);

    if (props?.navigateTo) {
      navigate(props.navigateTo);
    } else {
      window.location.href = results.redirectUrl ?? env.mindOnlyUrl;
    }
  };

  const renderQuizResult = () => {
    if (quizModel !== undefined) {
      const modelInstance = new quizModel();
      const modelProps = modelInstance.getResultPageProps(results);
      const ResultComponent = modelInstance.getResultComponent();

      return (
        <ResultComponent
          onSendReportSuccess={onSendReportSuccess}
          {...modelProps}
        />
      );
    }

    return <PageNotFound />;
  };

  return {
    isLoading,
    renderQuizResult,
  };
};

export default useResultPage;
