import { Link } from 'react-router-dom';
import env from '../../../env';
import SubmitButton from '../../SubmitButton';
import ValidationError from '../../ValidationError';
import useSendReportForm from '../hooks/useSendReportForm';
import * as utils from '../../../libs/utils/form.utils';

const SendReportForm = (props: any) => {
  const { form } = useSendReportForm(props);

  const getFieldProps = (field: string) => utils.getFieldProps(form, field);

  const isInvalidField = (field: string) => utils.isInvalidField(form, field);

  const checkedCandler = (e: any, field: string) =>
    form.setFieldValue(field, e.target.checked);

  const onSubmit = (e: any) => {
    e.preventDefault();
    form.submitForm();
  };

  return (
    <form className='send-report-form' onSubmit={onSubmit}>
      <div className={isInvalidField('name') ? 'mb-2' : 'mb-4'}>
        <input placeholder='Your full name' {...getFieldProps('name')} />
        {isInvalidField('name') && <ValidationError error={form.errors.name} />}
      </div>
      <div className={isInvalidField('email') ? 'mb-2' : 'mb-4'}>
        <input placeholder='Your email' {...getFieldProps('email')} />
        {isInvalidField('email') && (
          <ValidationError error={form.errors.email} />
        )}
      </div>
      <div className='mb-2'>
        <div className='form-check d-flex text-purple-dark'>
          <input
            id='terms'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => checkedCandler(e, 'terms')}
            checked={form.values.terms}
          />
          <label className='form-check-label ms-2 fw-light' htmlFor='terms'>
            {'I agree with '}
            <Link
              to={`${env.mindOnlyUrl}/terms`}
              target='_blank'
              className='text-purple-dark'
            >
              terms and conditions
            </Link>
            {' and '}
            <Link
              to={`${env.mindOnlyUrl}/privacy-policy`}
              target='_blank'
              className='text-purple-dark'
            >
              privacy policy
            </Link>
          </label>
        </div>
        {isInvalidField('terms') && (
          <ValidationError error={form.errors.terms} />
        )}
      </div>
      <div className='mb-4'>
        <div className='form-check d-flex text-purple-dark'>
          <input
            id='email_consent'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => checkedCandler(e, 'email_consent')}
            checked={form.values.email_consent}
          />
          <label
            className='form-check-label ms-2 fw-light'
            htmlFor='email_consent'
          >
            I agree to receive emails from The Attachment Project.
          </label>
        </div>
        {isInvalidField('email_consent') && (
          <ValidationError error={form.errors.email_consent} />
        )}
      </div>

      <SubmitButton
        isSubmitting={form.isSubmitting}
        className='mw-260'
        bsColor='primary'
      >
        Show my report
      </SubmitButton>
    </form>
  );
};

export default SendReportForm;
