const initialState = {
  quiz: null,
  quizStat: null,
  surveyQuestions: {
    questions: {
      data: [],
      current_page: null,
      last_page: null,
      per_page: null,
      total: null,
    },
    userAnswers: [],
  },
};

export default initialState;
