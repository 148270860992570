import * as questionTypes from '../../../libs/helpers/questionTypeHelper';
import { useEffect, useState } from 'react';
import { IAnswer } from '../forms/question/types';
import Question from '../../../interfaces/Question';
import UserAnswer from '../../../interfaces/UserAnswer';
import QuizSurvey from '../../../interfaces/QuizSurvey';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';

interface Props {
  situationSurvey: QuizSurvey;
  form: any;
  validateOnChange?: boolean;
  onSubmit: () => any;
}

const useSituation = ({
  situationSurvey,
  form,
  validateOnChange = false,
  onSubmit,
}: Props) => {
  const [errors, setErrors]: any = useState({});
  const [overrideFocus, setOverrideFocus]: any = useState(false);

  const questions: Question[] = situationSurvey?.survey?.questions || [];

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: questions.map((q) => {
      const {
        answer_id = null,
        answer_value = null,
        answer_text = null,
      } = questionTypes.getQuestionAnswer(q, form.values.situation) || {};

      return {
        value: answer_id || answer_value || answer_text,
        required: q.skippable !== 1,
      };
    }),
  });

  useEffect(() => {
    setQuestionIndex(-1);
  }, [questionIndex, situationSurvey]);

  useEffect(() => {
    validateOnChange && validateForm();
  }, [form.values.situation]);

  useEffect(() => {
    if (overrideFocus) {
      setQuestionIndex(-1);
      setOverrideFocus(false);
    }
  }, [overrideFocus]);

  const submitForm = (e: any) => {
    e.preventDefault();

    const { valid, errors } = validateForm();

    if (valid) {
      onSubmit();
    } else {
      const firstError: any = Object.values(errors)?.[0];
      setQuestionIndex(firstError.index || -1);
    }
  };

  const validateForm = () => {
    const errors: any = {};

    questions.forEach((q: Question, idx: number) => {
      if (q.skippable) {
        return;
      }

      const qAnswer = questionTypes.getQuestionAnswer(q, form.values.situation);

      if (!qAnswer) {
        errors[q.id] = { index: idx, message: 'Please select your answer.' };
      }
    });

    setErrors(errors);

    return { valid: Object.keys(errors).length < 1, errors };
  };

  const updateSituation = (
    question: Question,
    answer: IAnswer,
    overrideFocus: boolean = true,
  ) => {
    const situation: any = [...form.values.situation];

    const existingAnswerIdx = situation.findIndex(
      (x: UserAnswer) => x.question_id === question.id,
    );

    const userAnswer: any = {
      survey_id: situationSurvey?.survey?.id,
      question_id: question.id,
      ...answer,
    };

    if (existingAnswerIdx !== -1) {
      situation[existingAnswerIdx] = userAnswer;
    } else {
      situation.push(userAnswer);
    }

    form.setFieldValue('situation', situation);

    if (overrideFocus) {
      setOverrideFocus(true);
    }
  };

  return {
    form,
    questionIndex,
    setQuestionIndex,
    questions,
    updateSituation,
    submitForm,
    errors,
  };
};

export default useSituation;
