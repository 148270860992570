import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { unsubscribe } from '../../../libs/api/userApi';
import * as Yup from 'yup';

interface Props {
  token: string;
  onSuccessCallback: (response: any) => void;
}

const useUnsubscribeForm = ({ token, onSuccessCallback }: Props) => {
  const validationSchema: any = Yup.object().shape({
    dont_send_marketing_emails: Yup.boolean(),
    dont_send_any_emails: Yup.boolean(),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    unsubscribe(token, values)
      .then((r) => onSuccessCallback(r))
      .catch(({ code, message }: any) => {
        if (code === 404) {
          toast.error('Invalid token.');
        } else {
          toast.error(message);
        }

        setSubmitting(false);
      });
  };

  const form = useFormik({
    initialValues: {
      dont_send_marketing_emails: 0,
      dont_send_any_emails: 0,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return {
    form,
  };
};

export default useUnsubscribeForm;
