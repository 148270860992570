import React from 'react';
import { InputProps } from './types';
import useDebounce from '../../../../libs/hooks/useDebounce';

const TextInput: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const defaultValue = userAnswer?.answer_value || '';

  const debounce = useDebounce();

  const changeHandler = (e: any) => {
    debounce(
      `question-${question.id}`,
      () =>
        onChange({
          answer_value: e.target.value,
        }),
      500,
    );
  };

  return (
    <input
      type='text'
      className='form-control'
      placeholder={question.description}
      defaultValue={defaultValue}
      onChange={changeHandler}
    />
  );
};

export default TextInput;
