import { Link, useParams } from 'react-router-dom';
import QuizLayout from '../../components/layout/QuizLayout';
import SuccessMessage from '../../components/success-message/SuccessMessage';
import './SuccessMessage.css';

const SuccessMessagePage = () => {
  const { message } = useParams();

  return (
    <QuizLayout showFooter={false} header={{ showVisitSite: true }}>
      <div className='success-message-page'>
        <SuccessMessage>{message}</SuccessMessage>
        <Link to='/' className='btn btn-secondary w-100 mt-5'>
          Go To Home
        </Link>
      </div>
    </QuizLayout>
  );
};

export default SuccessMessagePage;
