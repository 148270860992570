import React from 'react';
import { InputProps } from './types';
import * as questionTypes from '../../../../libs/helpers/questionTypeHelper';
import TextInput from './TextInput';
import DateInput from './DateInput';
import RadioInput from './RadioInput';
import TileInput from './TileInput';
import RangeInput from './RangeInput';
import LocationInput from './LocationInput';
import SelectInput from './SelectInput';

const componentMap: any = {
  [questionTypes.FREE_TEXT]: TextInput,
  [questionTypes.DATE]: DateInput,
  [questionTypes.MULTI_CHOICE]: RadioInput,
  [questionTypes.TILE_PICKER]: TileInput,
  [questionTypes.LOCATION_PICKER]: LocationInput,
  [questionTypes.SELECT]: SelectInput,
  default: RangeInput,
};

const QuestionForm: React.FC<InputProps> = ({
  question,
  userAnswer,
  onChange,
}) => {
  const { survey_question_type: type } = question;
  const InputComponent = type
    ? componentMap[type.key] || componentMap.default
    : componentMap.default;

  return (
    <InputComponent
      question={question}
      userAnswer={userAnswer}
      onChange={onChange}
    />
  );
};

export default QuestionForm;
