import MainLayout from '../../../components/layout/MainLayout';
import OpenGraph from '../../../components/OpenGraph';
import Loader from '../../../components/quiz/loader/Loader';
import useOpenGraph from './useOpenGraph';
import useResultPage from './useResultPage';

const ResultPage = () => {
  const { isLoading, renderQuizResult } = useResultPage();

  const ogProps = useOpenGraph();

  return (
    <>
      <OpenGraph {...ogProps} />
      <MainLayout showVisitSite={true}>
        {isLoading ? <Loader /> : renderQuizResult()}
      </MainLayout>
    </>
  );
};

export default ResultPage;
