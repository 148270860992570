import { handleError, handleResponse } from './utils';
import axiosAuthInstance from './axios/authInstance';
import axiosGuestInstance from './axios/guestInstance';

const baseUrl = '/quiz-stat';

export function getQuizStat(params) {
  return axiosAuthInstance
    .get(baseUrl, params)
    .then(handleResponse)
    .catch(handleError);
}

export function createQuizStat(params) {
  return axiosGuestInstance
    .post(baseUrl, params)
    .then(handleResponse)
    .catch(handleError);
}

export function continueLater(params) {
  return axiosAuthInstance
    .put(`${baseUrl}/continue-later`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function sendReport(params) {
  return axiosAuthInstance
    .post(`${baseUrl}/send-report`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function getResults(params) {
  return axiosAuthInstance
    .get(`${baseUrl}/results`, params)
    .then(handleResponse)
    .catch(handleError);
}