import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../modal/Modal';
import ContinueLaterForm from './forms/ContinueLaterForm';

const ContinueLaterModal = ({ children, ...props }: any) => {
  const [isOpened, setIsOpened] = useState(false);

  const onSuccessCallback = () => {
    setIsOpened(false);
    toast.success('Thanks for registration.');
  };

  return (
    <Modal
      size='sm'
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      toggleBtn={children}
      toggleProps={props}
    >
      <Modal.Body>
        <div className='text-center mb-4'>
          <h5>Continue later</h5>
          <small>
            Register your email to save your progress and proceed later.
          </small>
        </div>
        <ContinueLaterForm onSuccessCallback={onSuccessCallback} />
      </Modal.Body>
    </Modal>
  );
};

export default ContinueLaterModal;
