import SubmitButton from '../../SubmitButton';
import ValidationError from '../../ValidationError';
import useContinueLaterForm from '../hooks/useContinueLaterForm';
import * as utils from '../../../libs/utils/form.utils';

const ContinueLaterForm = (props: any) => {
  const { form } = useContinueLaterForm(props);

  const getFieldProps = (field: string) => utils.getFieldProps(form, field);

  const isInvalidField = (field: string) => utils.isInvalidField(form, field);

  const checkedCandler = (e: any, field: string) =>
    form.setFieldValue(field, e.target.checked);

  const onSubmit = (e: any) => {
    e.preventDefault();
    form.submitForm();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='mb-4'>
        <input placeholder='Your full name' {...getFieldProps('name')} />
        {isInvalidField('name') && <ValidationError error={form.errors.name} />}
      </div>
      <div className='mb-4'>
        <input placeholder='Your email' {...getFieldProps('email')} />
        {isInvalidField('email') && (
          <ValidationError error={form.errors.email} />
        )}
      </div>
      <div className='mb-4 px-1'>
        <div className='form-check d-flex align-items-center'>
          <input
            id='agree'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => checkedCandler(e, 'agree')}
            checked={form.values.agree}
          />
          <label className='form-check-label fw-light ms-3' htmlFor='agree'>
            <small>I agree to receive emails from attachment</small>
          </label>
        </div>
        {isInvalidField('agree') && (
          <ValidationError error={form.errors.agree} />
        )}
      </div>
      <SubmitButton
        isSubmitting={form.isSubmitting}
        bsColor='primary'
        bsSpinnerColor='secondary'
      >
        Save
      </SubmitButton>
    </form>
  );
};

export default ContinueLaterForm;
