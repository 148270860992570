import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import QuizLayout from '../../components/layout/QuizLayout';
import UnsubscribeForm from '../../components/quiz/forms/UnsubscribeForm';
import SuccessMessage from '../../components/success-message/SuccessMessage';
import './UnsubscribePage.css';

const UnsubscribePage = () => {
  const [isUnsubscribed, setIsUnsubscribed]: [boolean, any] = useState(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  return (
    <QuizLayout>
      <div className='unsubscribe-page'>
        {isUnsubscribed ? (
          <SuccessMessage className="success-message">
            You have been unsubscribed.
          </SuccessMessage>
        ) : (
          <UnsubscribeForm
            token={token}
            onSuccessCallback={() => setIsUnsubscribed(true)}
          />
        )}
      </div>
    </QuizLayout>
  );
};

export default UnsubscribePage;
