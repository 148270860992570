import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useGtag from './useGtag';

const usePageViewTracker = () => {
  const isMounted = useRef(false);
  const { pathname } = useLocation();

  const { sendEvent } = useGtag();

  useEffect(() => {
    if (isMounted.current) {
      sendPageViewEvent();
    }
  }, [pathname]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const sendPageViewEvent = () => {
    setTimeout(() => {
      sendEvent('page_view');
    }, 2000);
  };
};

export default usePageViewTracker;
