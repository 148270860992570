import { Helmet } from 'react-helmet';

interface OpenGraphProps {
  title: string;
  description?: string;
  image?: string;
}

const OpenGraph: React.FC<OpenGraphProps> = ({
  title,
  description = null,
  image = null,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />

      {description && <meta name='description' content={description} />}
      {description && <meta property='og:description' content={description} />}

      {image && <meta property='og:image' content={image} />}
    </Helmet>
  );
};

export default OpenGraph;
